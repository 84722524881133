/* eslint-disable no-shadow */
enum OBJECT_STATE {
  DRAFT = 'draft',
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
  EMBARGOED = 'embargoed',
  DELETED = 'deleted',
}

export { OBJECT_STATE };
